<template>
  <v-bottom-navigation
    id="mobile-app-bar"
    fixed
    dark
    background-color="blue darken-3"
    height="50"
  >
    <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; align-items: center;">
      <base-notification-pessoa v-if="this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.length > 0 && this.$store.state.user.roles[0].slug === 'produtor'" />

      <base-notification-pessoa v-if="this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.length > 0 && this.$store.state.user.roles[0].slug === 'tecnico'" />

      <base-carrinho v-if="this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.length > 0 && (this.$store.state.user.roles[0].slug === 'tecnico' || this.$store.state.user.roles[0].slug === 'produtor') && !this.$route.path.includes('carrinho') && !this.$route.path.includes('pedido')" />

      <base-notification-adm v-if="this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.length > 0 && (this.$store.state.user.roles[0].slug === 'gestor' || this.$store.state.user.roles[0].slug === 'supervisor')" />

      <base-menu-profile />

      <v-btn
        icon
        small
        color="white"
        title="Sair do portal"
        class="card-icon mx-2"
        @click="mensagem"
      >
        <v-icon>
          mdi-logout
        </v-icon>
      </v-btn>
    </div>
  </v-bottom-navigation>
</template>

<script>
  import Swal from 'sweetalert2'
  import { logout } from '@/services/AuthService'

  export default {
    name: 'DashboardCoreMobileAppBar',

    data () {
      return {

      }
    },
    methods: {
      mensagem () {
        if ((this.$store.state.user.roles[0].slug === 'tecnico' || this.$store.state.user.roles[0].slug === 'produtor') && this.$store.state.carrinho.produtos.length > 0) {
          Swal.fire({
            title: 'Atenção!',
            html: 'Ao sair da plataforma os produtos contidos<br/> no seu carrinho serão excluídos.<br/><br/> Tem certeza que deseja sair?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonColor: '#109010',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            focusConfirm: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.sair()
            }
          })
        } else {
          Swal.fire({
            title: 'Tem certeza que deseja sair?',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#109010',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sair',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            focusConfirm: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.sair()
            }
          })
        }
      },
      sair () {
        logout()
      },
    },
  }
</script>
